import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Language } from '@utils/types/lang';

interface LanguageSwitcherProps {
  langs: Language[];
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ langs }) => {
  const { i18n, t } = useTranslation(['routes']);

  const [expanded, setExpanded] = useState(false);
  const currentPath = window.location.pathname;
  const routes = i18n.getResourceBundle(i18n.languages[0], 'routes');

  const changeLanguage = (newLang: string) => {
    const currentRouteKey = Object.keys(routes).find((key: string) => routes[key] === currentPath);

    const newRoute = t(`routes:${currentRouteKey}`, {
      lng: newLang,
    });

    i18n.changeLanguage(newLang);

    window.location.href = newRoute;

    document.cookie = 'nanoslang=' + newLang + '; path=/';
  };

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <div className={styles.langSwitcher}>
      {langs
        .map((lng) => {
          return lng?.active ? (
            <span
              className={styles.isActive}
              key={lng?.code}
              onClick={toggleExpanded}
            >
              <img src={lng.country_flag} alt={lng?.native_name} />
              {lng?.native_name}
            </span>
          ) : (
            <span key={lng?.code} onClick={() => changeLanguage(lng?.code)}>
              <img src={lng.country_flag} alt={lng?.native_name} />
              {lng?.native_name}
            </span>
          );
        })}
    </div>
  );
};

export default LanguageSwitcher;
