import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LayoutSection, { SECTION_BACKGROUND } from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import SidebarWrapper from '@components/SidebarWrapper';
import queryString from 'query-string';
import OrderRecap from '@components/OrderRecap';
import Card from '@components/Card';
import CopyToClipboard from '@components/CopyToClipboard';
import usePaymentRecap from '@hooks/usePaymentRecap';
import SkeletonBasic from '@components/SkeletonBasic';
import Button from '@components/Button';
import { CartContext } from '@contexts/CartContext';

export default function BankTransferPayment() {
  const { t } = useTranslation(['payments', 'routes', 'common']);

  const location = useLocation();
  const navigate = useNavigate();
  const { onEmptyCart } = React.useContext(CartContext);

  const { query } = queryString?.parseUrl(location.search);
  const paymentId: any = query?.paymentId ?? null;

  React.useEffect(() => {
    if (!Boolean(paymentId)) {
      navigate(t('routes:home'));
    }
  }, [paymentId, navigate, t]);

  React.useEffect(() => {
    if (paymentId) {
      onEmptyCart();
    }
  }, [onEmptyCart, paymentId]);

  const { data, isLoading, isError, error } = usePaymentRecap();
  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage totalWhite={true}>
        <LayoutSection
          noMarginBottom
          hasSide
          isCentered
          backgroundColor={SECTION_BACKGROUND.WHITE}
        >
          <div className={styles.container}>
            <h2>{t('payments:bankTransfer.title')}</h2>
            {!isError ? (
              <>
                <div className={styles.cardWrapper}>
                  <Card>
                    <div className={styles.inline}>
                      <div className={styles.step}>
                        <p>1</p>
                      </div>
                      <p>
                        <strong>
                          {t('payments:bankTransfer.blocks.block1.title.part1')}
                        </strong>{' '}
                        {t('payments:bankTransfer.blocks.block1.title.part2')}
                      </p>
                    </div>
                    <div className={styles.dark}>
                      <div>
                        <strong>
                          {t(
                            'payments:bankTransfer.blocks.block1.labels.amount'
                          )}
                        </strong>
                        {isLoading ? (
                          <SkeletonBasic width={150} height={16} />
                        ) : (
                          <CopyToClipboard>{`${data?.total} ${data?.currency}`}</CopyToClipboard>
                        )}
                      </div>
                      <div>
                        <strong>
                          {t(
                            'payments:bankTransfer.blocks.block1.labels.accountHolder'
                          )}
                        </strong>
                        <p>{`${data?.bankTransferInfo.accountHolder ?? ''}`}</p>
                      </div>
                      <div>
                        <strong>
                          {t('payments:bankTransfer.blocks.block1.labels.bank')}
                        </strong>
                        <p>{`${data?.bankTransferInfo.bank ?? ''}`}</p>
                      </div>
                      <div>
                        <strong>
                          {t('payments:bankTransfer.blocks.block1.labels.iban')}
                        </strong>
                        {isLoading ? (
                          <SkeletonBasic width={150} height={16} />
                        ) : (
                          <CopyToClipboard>
                            {data?.bankTransferInfo.iban ?? ''}
                          </CopyToClipboard>
                        )}
                      </div>
                      <div>
                        <strong>
                          {t(
                            'payments:bankTransfer.blocks.block1.labels.bicSwift'
                          )}
                        </strong>
                        {isLoading ? (
                          <SkeletonBasic width={150} height={16} />
                        ) : (
                          <CopyToClipboard>
                            {data?.bankTransferInfo.bicSwift ?? ''}
                          </CopyToClipboard>
                        )}
                      </div>
                      <div>
                        <strong>
                          {t(
                            'payments:bankTransfer.blocks.block1.labels.reasonTransfer'
                          )}
                        </strong>
                        {isLoading ? (
                          <SkeletonBasic width={150} height={16} />
                        ) : (
                          <CopyToClipboard>{`${data?.id ?? ''}`}</CopyToClipboard>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
                <div className={styles.cardWrapper}>
                  <Card>
                    <div className={styles.inline}>
                      <div className={styles.step}>
                        <p>2</p>
                      </div>
                      <p>
                        <strong>
                          {t('payments:bankTransfer.blocks.block2.title.part1')}
                        </strong>
                        {t('payments:bankTransfer.blocks.block2.title.part2')}
                      </p>
                    </div>
                  </Card>
                </div>
                <div className={styles.cardWrapper}>
                  <Card>
                    <div className={styles.inline}>
                      <div className={styles.step}>
                        <p>3</p>
                      </div>
                      <p>
                        <strong>
                          {t('payments:bankTransfer.blocks.block3.title.part1')}
                        </strong>{' '}
                        {t('payments:bankTransfer.blocks.block3.title.part2')}
                      </p>
                    </div>
                  </Card>
                </div>
              </>
            ) : (
              <>
                <h4 style={{ marginBottom: 8 }}>{error?.message}</h4>
                <Button onClick={() => navigate(t('routes:checkout'))}>
                  {t('common:shops.retry')}
                </Button>
              </>
            )}
          </div>
          <SidebarWrapper>{!isError && <OrderRecap isSide />}</SidebarWrapper>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}
