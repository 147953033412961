import { divide } from 'lodash';
import { forceToInt, forceToFloat } from '@utils/types/helpers';
import { CartItemType, ProductVersion } from '@utils/types/store';


declare global {
    interface Window {
        dataLayer: any;
        userId: string;
    }
}

export interface DataLayerEvent {
    event: string;
    [key: string]: any;
}

export interface DataLayerEventCartItem {
    item_id: number;
    item_name: string;
    item_brand: string;
    price: number;
    quantity: number;
}

const addUserIdToDataLayerEvent = (event: DataLayerEvent) => {
    if (window.userId && window.userId.length > 0) {
        event.user_id = window.userId;
    }
    return event;
};

export const dataLayerPush = (event: DataLayerEvent) => {
    if (typeof window != 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }
};

export const dataLayerPushUserId = () => {
    if (window.userId && window.userId.length > 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ user_id: window.userId });
    }
};

export const dataLayerPushLogin = (userId: string) => {
    if (userId && userId.length > 0) {
        window.userId = userId;
        const dataLayerEvent: DataLayerEvent = {
            event: 'login',
            user_id: window.userId
        };

        dataLayerPush({ event: '', ecommerce: null }); // Clear dataLayer ecommerce object
        dataLayerPush(dataLayerEvent);
    }
};

export const dataLayerPushAddToCart = (id: number, name: string, price: number, quantity: number) => {
    const item: DataLayerEventCartItem = {
        item_id: id,
        item_name: name,
        item_brand: 'Nanosystems',
        price: price,
        quantity: quantity,
    };

    const items: Array<DataLayerEventCartItem> = [item];

    let dataLayerEvent: DataLayerEvent = {
        event: 'add_to_cart',
        ecommerce: {
            currency: 'EUR',
            items: items
        }
    };

    dataLayerEvent = addUserIdToDataLayerEvent(dataLayerEvent);

    dataLayerPush({ event: '', ecommerce: null }); // Clear dataLayer ecommerce object
    dataLayerPush(dataLayerEvent);
};

export const dataLayerPushBeginCheckout = (cartItems: Array<CartItemType>, cartTotalAmount: number) => {
    const items: Array<DataLayerEventCartItem> = [];

    let i, item: DataLayerEventCartItem;
    for (i = 0; i < cartItems.length; i++) {
        item = {
            item_id: cartItems[i].id,
            item_name: cartItems[i].title,
            item_brand: 'Nanosystems',
            price: divide(cartItems[i].price, cartItems[i].quantity),
            quantity: cartItems[i].quantity,
        };
        items.push(item);
    }

    let dataLayerEvent: DataLayerEvent = {
        event: 'begin_checkout',
        ecommerce: {
            currency: 'EUR',
            value: cartTotalAmount,
            items: items
        }
    };

    dataLayerEvent = addUserIdToDataLayerEvent(dataLayerEvent);

    dataLayerPush({ event: '', ecommerce: null }); // Clear dataLayer ecommerce object
    dataLayerPush(dataLayerEvent);
};

export const dataLayerPushPurchase = (orderId: string, orderTotal: number, orderItems: Array<Partial<ProductVersion>>) => {
    const items: Array<DataLayerEventCartItem> = [];

    let i, cartItem: Partial<ProductVersion>, item: DataLayerEventCartItem;
    for (i = 0; i < orderItems.length; i++) {
        cartItem = orderItems[i];
        item = {
            item_id: cartItem.id ? forceToInt(cartItem.id) : 0,
            item_name: cartItem.name || '',
            item_brand: 'Nanosystems',
            price: cartItem.price ? forceToFloat(cartItem.price) : 0,
            quantity: cartItem.quantity ? forceToInt(cartItem.quantity) : 0
        };
        items.push(item);
    }

    let dataLayerEvent: DataLayerEvent = {
        event: 'purchase',
        ecommerce: {
            transaction_id: orderId,
            currency: 'EUR',
            value: orderTotal,
            items: items
        }
    };

    dataLayerEvent = addUserIdToDataLayerEvent(dataLayerEvent);

    dataLayerPush({ event: '', ecommerce: null }); // Clear dataLayer ecommerce object
    dataLayerPush(dataLayerEvent);
};