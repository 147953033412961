import { useEffect, useState } from 'react';
import { SupremoFeatures, SupremoFeaturesList } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';
import Tooltip from 'rc-tooltip';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';

export function renderSupremoFeaturesColumn(
  columns: SupremoFeaturesList[]
): SupremoFeatures[] {
  return columns.map((el: SupremoFeaturesList) => {
    return {
      feature: (
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {el?.name}
          {el?.tooltip && (
            <Tooltip
              placement="top"
              overlay={el?.tooltip}
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <i
                className={'icon-info'}
                style={{
                  marginLeft: 8,
                  cursor: 'pointer',
                }}
              ></i>
            </Tooltip>
          )}
        </p>
      ),
      solo: (
        <i
          style={{ color: el?.values[0] ? '#3CC13B' : 'red', fontSize: '32px' }}
          className={`${el?.values[0] ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      business: (
        <i
          style={{ color: el?.values[1] ? '#3CC13B' : 'red', fontSize: '32px' }}
          className={`${el?.values[1] ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),

      professional: (
        <i
          style={{ color: el?.values[2] ? '#3CC13B' : 'red', fontSize: '32px' }}
          className={`${el?.values[2] ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
    };
  });
}

function SupremoAccessAndControlTable() {
  const { i18n } = useTranslation();
  const [accessAndControlFeaturesData, setAccessAndControlFeaturesData] = useState<SupremoFeaturesList[]>([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  const hideColumn = windowWidth < 1200;

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const columnHelper = createColumnHelper<any>();

  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.solo, {
      id: 'solo',
      cell: (info) => info.getValue(),
      header: () => <span>Solo</span>,
    }),
    columnHelper.accessor((row) => row.business, {
      id: 'business',
      cell: (info) => info.getValue(),
      header: () => <span>Business</span>,
    }),
    columnHelper.accessor((row) => row.professional, {
      id: 'professional',
      cell: (info) => info.getValue(),
      header: () => <span>Professional</span>,
    }),
  ];

  useEffect(() => {
    const loadSupremoFeaturesData = async () => {
      const lang = i18n.language || 'en';
      try {
        const module = await import(`@assets/data/supremo/features/${lang.toLowerCase()}/accessAndControl.json`);
        setAccessAndControlFeaturesData(module.default);
      } catch (error) {
        console.error('Error loading language file:', error);
      }
    };

    loadSupremoFeaturesData();
  }, [i18n.language]);

  const accessAndControlFeatures = [
    ...renderSupremoFeaturesColumn(accessAndControlFeaturesData),
  ];

  return (
    <BasicTable
      isHeaderHidden={!hideColumn}
      isTitleRow
      data={accessAndControlFeatures}
      columns={versionColumns}
    />
  );
}

export default SupremoAccessAndControlTable;
