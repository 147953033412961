import { useEffect, useState } from 'react';
import { SupremoFeaturesList } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';
import { renderSupremoFeaturesColumn } from '@components/SupremoAccessAndControlTable';

function SupremoAssistanceAndCustomizationTable() {
  const { i18n } = useTranslation();
  const [assistanceAndCustomizationFeaturesData, setAssistanceAndCustomizationFeaturesData] = useState<SupremoFeaturesList[]>([]);

  const columnHelper = createColumnHelper<any>();

  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.solo, {
      id: 'solo',
      cell: (info) => info.getValue(),
      header: () => <span>Solo</span>,
    }),
    columnHelper.accessor((row) => row.business, {
      id: 'business',
      cell: (info) => info.getValue(),
      header: () => <span>Business</span>,
    }),
    columnHelper.accessor((row) => row.professional, {
      id: 'professional',
      cell: (info) => info.getValue(),
      header: () => <span>Professional</span>,
    }),
  ];

  useEffect(() => {
      const loadSupremoFeaturesData = async () => {
        const lang = i18n.language || 'en';
        try {
          const module = await import(`@assets/data/supremo/features/${lang.toLowerCase()}/assistanceAndCustomization.json`);
          setAssistanceAndCustomizationFeaturesData(module.default);
        } catch (error) {
          console.error('Error loading language file:', error);
        }
      };

      loadSupremoFeaturesData();
    }, [i18n.language]);

  const assistanceAndCustomizationFeatures = [
    ...renderSupremoFeaturesColumn(assistanceAndCustomizationFeaturesData),
  ];

  return (
    <BasicTable
      isHeaderHidden
      isTitleRow
      data={assistanceAndCustomizationFeatures}
      columns={versionColumns}
    />
  );
}

export default SupremoAssistanceAndCustomizationTable;
