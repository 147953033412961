import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import SupremoControlLogo from '@assets/images/control.svg';
import UraniumBackupLogo from '@assets/images/uranium-backup.svg';
import SupremoConsoleLogo from '@assets/images/supremo-console.svg';
import styles from './styles.module.scss';
import Avatar from '@components/Avatar';
import useGetUserInfo from '@hooks/useUserInfo';
import { CartContext } from '@contexts/CartContext';
import { UserContext } from '@contexts/UserContext';
import { useLogout } from '@hooks/useLogout';
import LanguageSwitcher from '@components/LangSwitcher';
//import NotificationBell from '@components/NotificationBell';
import flag_EN from '@assets/images/flags/flag_en.svg';
import flag_IT from '@assets/images/flags/flag_it.svg';
import flag_FR from '@assets/images/flags/flag_fr.svg';
import flag_DE from '@assets/images/flags/flag_de.svg';
import flag_PTBR from '@assets/images/flags/flag_pt_br.svg';
import flag_ES from '@assets/images/flags/flag_es.svg';
import { Language } from '@utils/types/lang';
import useResize from '@hooks/useResize';

type Props = {
  isAvatarHighlighted: boolean;
};
const NavBar = ({ isAvatarHighlighted }: Props) => {
  const { t, i18n } = useTranslation(['routes', 'common']);
  const [isProductOpen, setIsProductOpen] = React.useState(false);
  const [isPurchaseOpen, setIsPurchaseOpen] = React.useState(false);
  const [isSupportOpen, setIsSupportOpen] = React.useState(false);
  const [isLangOpen, setIsLangOpen] = React.useState(false);
  const { totalQuantity } = React.useContext(CartContext);
  const isMobile = useResize(1200);
  const langs: Language[] = React.useMemo(() => {
    return [
      {
        code: 'en',
        country_flag: flag_EN,
        native_name: 'English',
        active: i18n.language?.toLowerCase() === 'en',
      },
      {
        code: 'fr',
        country_flag: flag_FR,
        native_name: 'Français',
        active: i18n.language?.toLowerCase() === 'fr',
      },
      {
        code: 'de',
        country_flag: flag_DE,
        native_name: 'Deutsch',
        active: i18n.language?.toLowerCase() === 'de',
      },
      {
        code: 'it',
        country_flag: flag_IT,
        native_name: 'Italiano',
        active: i18n.language?.toLowerCase() === 'it',
      },
      {
        code: 'pt-br',
        country_flag: flag_PTBR,
        native_name: 'Português',
        active: i18n.language?.toLowerCase() === 'pt-br',
      },
      {
        code: 'es',
        country_flag: flag_ES,
        native_name: 'Español',
        active: i18n.language?.toLowerCase() === 'es',
      },
    ];
  }, [i18n.language]);

  React.useEffect(() => {
    langs.sort((a, b) => {
      if (a.active && !b.active) return -1;
      if (!a.active && b.active) return 1;
      return 0;
    });
  }, [langs]);

  const navigate = useNavigate();

  function setProductDropdown() {
    setIsProductOpen(!isProductOpen);
  }
  function setPurchaseDropdown() {
    setIsPurchaseOpen(!isPurchaseOpen);
  }
  function setSupportDropdown() {
    setIsSupportOpen(!isSupportOpen);
  }
  function setLangDropdown() {
    if (isMobile) {
      setIsLangOpen(!isLangOpen);
    }
  }

  const onNavigateToAccount = React.useCallback(() => {
    navigate(t('routes:dashboard'));
  }, [navigate, t]);
  const [openLangMobile, setOpenLangMobile] = React.useState(false);
  const { isLoggedIn } = React.useContext(UserContext);
  const { mutate } = useLogout();

  const openSubMenuMobile = () => {
    setOpenLangMobile(!openLangMobile);
  };
  const logOut = () => {
    mutate();
  };
  const { data } = useGetUserInfo(isLoggedIn);

  return (
    <>
      <nav className="header__menu">
        <ul>
          <li
            onClick={openSubMenuMobile}
            className={`lang-menu-mobile ${openLangMobile ? 'expanded' : ''}`}
          >
            <Link to={'#'} onClick={setLangDropdown}>
              {langs
                .map((lng) => {
                  return (
                    lng?.active && (
                      <span className={styles.isActive} key={lng?.code}>
                        <img
                          style={{
                            borderRadius: '1.75px',
                            width: '22px',
                            height: '16px',
                          }}
                          src={lng.country_flag}
                          alt={lng?.native_name}
                        />
                        {lng?.native_name}
                      </span>
                    )
                  );
                })}
            </Link>

            <ul className="sub-menu">
              <LanguageSwitcher langs={langs} />
            </ul>
          </li>
          {isLoggedIn && (
            <li className="custom-mobile-nav ">
              <div
                className={`${styles.avatar} ${styles.mobile} custom-mobile-nav `}
              >
                <Avatar
                  onClick={onNavigateToAccount}
                  image={''}
                  name={data?.username?.toUpperCase() ?? ''}
                  isAvatarHighlighted={isAvatarHighlighted}
                />

                <p
                  onClick={onNavigateToAccount}
                  className={styles.logoutMobile}
                >
                  {t('common:navigation.hello', {
                    name: data?.username,
                  })}
                </p>
              </div>
            </li>
          )}
          <li id="menu-item-1" className="menu-item">
            <a href={t('links:nanosystems.aboutUs')}>
              {t('common:navigation.aboutUs')}
            </a>
          </li>
          <li id="menu-item-2"
            className={`${isProductOpen
              ? 'menu-item menu-item-has-children open'
              : 'menu-item menu-item-has-children'
              }`}
          >
            <Link to={'#'} onClick={setProductDropdown}>
              {t('common:navigation.products')}
            </Link>
            <ul className="sub-menu">
              <li id="menu-item-2-1" className="menu-item">
                <a className="logo-menu" href={t('links:nanosystems.home') + '#info-supremo-control'}>
                  <small> {t('common:navigation.remoteControl')}</small>
                  <img src={SupremoControlLogo} alt={t('common:navigation.supremoControl')} />
                </a>
              </li>
              <li id="menu-item-2-2" className="menu-item">
                <a className="logo-menu" href={t('links:nanosystems.home') + '#info-uranium'}>
                  <small> {t('common:navigation.diskBkp')}</small>
                  <img src={UraniumBackupLogo} alt={t('common:navigation.uraniumBackup')} />
                </a>
              </li>
              <li id="menu-item-2-3" className="menu-item">
                <a className="logo-menu" href={t('links:nanosystems.home') + '#info-supremo-console'}>
                  <small> {t('common:navigation.supremoConsoleDesc')}</small>
                  <img src={SupremoConsoleLogo} alt={t('common:navigation.supremoConsole')} />
                </a>
              </li>
            </ul>
          </li>
          <li id="menu-item-3"
            className={`${isPurchaseOpen
              ? 'menu-item menu-item-has-children open'
              : 'menu-item menu-item-has-children'
              }`}
          >
            <Link to={'#'} onClick={setPurchaseDropdown}>
              {t('common:navigation.purchase')}
            </Link>
            <ul className="sub-menu">
              <li id="menu-item-3-1" className="menu-item">
                <Link to={t('routes:home')}>{t('common:navigation.shop')}</Link>
              </li>
              <li id="menu-item-3-2" className="menu-item">
                <a href={t('links:nanosystems.becomeReseller')}>{t('common:navigation.becomeReseller')}</a>
              </li>
            </ul>
          </li>
          <li id="menu-item-4"
            className={`${isSupportOpen
              ? 'menu-item menu-item-has-children open'
              : 'menu-item menu-item-has-children'
              }`}
          >
            <Link to={'#'} onClick={setSupportDropdown}>
              {t('common:navigation.support')}
            </Link>
            <ul className="sub-menu">
              <li id="menu-item-4-1" className="menu-item">
                <a className="logo-menu" href={t('links:supremo.support')}>
                  <img src={SupremoControlLogo} alt={t('common:navigation.supremoControl')} />
                </a>
              </li>
              <li id="menu-item-4-2" className="menu-item">
                <a className="logo-menu" href={t('links:uranium.support')}>
                  <img src={UraniumBackupLogo} alt={t('common:navigation.uraniumBackup')} />
                </a>
              </li>
            </ul>
          </li>
          <li id="menu-item-5" className="menu-item">
            <a href={t('links:nanosystems.contacts')}>
              {t('common:navigation.contact')}
            </a>
          </li>

          {!isLoggedIn ? (
            <li className="custom-mobile-nav">
              <Link className="cta secondary block" to={t('routes:signin')}>
                {t('common:navigation.access')}
              </Link>
            </li>
          ) : (
            <li className="custom-mobile-nav">
              <Link onClick={logOut} to={'#'}>
                {t('common:navigation.logout')}
              </Link>
            </li>
          )}
        </ul>
      </nav>
      <nav className="header__cart">
        <Link to={Boolean(totalQuantity) ? t('routes:cart') : ''}>
          <i className="icon-shopping-cart"></i>
          <span>{totalQuantity > 99 ? '99+' : totalQuantity}</span>
        </Link>
      </nav>
      {/*isLoggedIn && (
        <nav className="header__notification">
          <Link to={t('routes:dashboard')}>
            <NotificationBell isActive={data?.hasUnreadArticles} />
            <span></span>
          </Link>
        </nav>
      )*/}
      <nav className="header__user">
        {!isLoggedIn ? (
          <Link className="cta secondary" to={t('routes:signin')}>
            {t('common:navigation.access')}
          </Link>
        ) : (
          <div className={styles.avatar}>
            <Avatar
              onClick={onNavigateToAccount}
              image={''}
              name={data?.username?.toUpperCase() ?? ''}
              isAvatarHighlighted={isAvatarHighlighted}
            />
            <div className={styles.logout}>
              <p onClick={onNavigateToAccount}>
                {t('common:navigation.hello', {
                  name: data?.username,
                })}
              </p>
              <span onClick={logOut}>{t('common:navigation.logout')}</span>
            </div>
          </div>
        )}
      </nav>
      <div className="lang-menu">
        <ul>
          <li
            id="menu-item-lang"
            className={`${
              isProductOpen
                ? 'menu-item menu-item-has-children open'
                : 'menu-item menu-item-has-children'
            }`}
          >
            <Link to={'#'} onClick={setLangDropdown}>
              {langs
                .map((lng) => {
                  return (
                    lng?.active && (
                      <span className={styles.isActive} key={lng?.code}>
                        <img
                          style={{ borderRadius: '1.75px' }}
                          src={lng.country_flag}
                          alt={lng?.native_name}
                        />
                      </span>
                    )
                  );
                })}
            </Link>
            <ul className="sub-menu">
              <LanguageSwitcher langs={langs} />
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBar;
