import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Shop from '@pages/Shop';
import SupremoControl from '@pages/SupremoControl';
import '@styles/main.scss';
import SignIn from '@pages/SignIn';
import SignUp from '@pages/SignUp';
import UraniumBackup from '@pages/UraniumBackup';
import CartScreen from '@pages/Cart';
import Checkout from '@pages/Checkout';
import SignInPayment from '@pages/SignInPayment';
import PasswordRecovery from '@pages/PasswordRecovery';
import ConfirmPaypalPayment from '@pages/ConfirmPaypalPayment';
import PaymentCheck from '@pages/PaymentCheck';
import BankTransferPayment from '@pages/BankTransferPayment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from '@pages/NotFound';
import ScrollToTop from '@components/ScrollToTop';
import Dashboard from '@pages/Dashboard';
import { UserContext } from '@contexts/UserContext';
import Modal from 'react-modal';
import PaymentMethods from '@pages/PaymentMethods';
import Account from '@pages/Account';
import Autorenew from '@pages/Autorenew';
import Autologin from '@pages/Autologin';
import Purchases from '@pages/Purchases';
import License from '@pages/License';
import UpgradeRenew from '@pages/UpgradeRenew';
import TrialLicenseInvite from '@pages/TrialLicenseInvite';
import { dataLayerPushUserId } from '@utils/tagManagerHelper';

function PrivateRoute({ children }: any) {
  const { t } = useTranslation('routes');
  const location = useLocation();
  const { isLoggedIn } = React.useContext(UserContext);
  const redirectRoute = `${location?.pathname}${location?.search}`;
  return isLoggedIn ? (
    children
  ) : (
    <Navigate state={{ redirectRoute }} to={t('signin')} />
  );
}

Modal.setAppElement('#root');

function App() {
  const { t } = useTranslation('routes');

  const location = useLocation();
  useEffect(() => { 
    dataLayerPushUserId();
  }, [location]);

  return (
    <div style={{ position: 'relative' }}>
      <ScrollToTop />

      <Routes>
        <Route path={t('home')}>
          <Route index element={<Shop />} />
          <Route path={t('uraniumBackup')} element={<UraniumBackup />} />
          <Route path={t('supremoControl')} element={<SupremoControl />} />
          <Route path={t('cart')} element={<CartScreen />} />
          <Route path={t('checkout')} element={<Checkout />} />
          <Route
            path={t('confirmPaypalPayment')}
            element={
              <PrivateRoute index>
                <ConfirmPaypalPayment />
              </PrivateRoute>
            }
          />

          <Route
            path={t('paymentCheck')}
            element={
              <PrivateRoute index>
                <PaymentCheck />
              </PrivateRoute>
            }
          />
          <Route
            path={t('bankTransfer')}
            element={
              <PrivateRoute index>
                <BankTransferPayment />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path={t('dashboard')}
          element={
            <PrivateRoute index>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={t('payments')}
          element={
            <PrivateRoute index>
              <PaymentMethods />
            </PrivateRoute>
          }
        />
        <Route
          path={t('upgrade')}
          element={
            <PrivateRoute index>
              <UpgradeRenew />
            </PrivateRoute>
          }
        />
        <Route
          path={t('purchases')}
          element={
            <PrivateRoute index>
              <Purchases />
            </PrivateRoute>
          }
        />
        <Route
          path={t('account')}
          element={
            <PrivateRoute index>
              <Account />
            </PrivateRoute>
          }
        />
        <Route
          path={t('license')}
          element={
            <PrivateRoute index>
              <License />
            </PrivateRoute>
          }
        />

        <Route path={t('signin')} element={<SignIn />} />
        <Route path={t('signinPayment')} element={<SignInPayment />} />
        <Route path={t('signup')} element={<SignUp />} />
        <Route path={t('passwordRecovery')} element={<PasswordRecovery />} />
        <Route path={t('autorenew')} element={<Autorenew />} />
        <Route path={t('autologin')} element={<Autologin />} />
        <Route path={t('trialLicenseInvite')} element={<TrialLicenseInvite />} />
        <Route path={'*'} element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={false}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
